/* eslint-disable react/jsx-one-expression-per-line */
// Basics
import React from 'react';
import './index.scss';
import { Link, StaticQuery, graphql } from 'gatsby';

// Utilites
import { includes } from 'lodash';

class AtomNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.handleClickMenu = this.handleClickMenu.bind(this);
  }

  handleClickMenu() {
    document.body.classList.toggle('navbar-open');
  }

  render() {
    const { className } = this.props;
    const self = this;

    return (
      <StaticQuery
        query={graphql`
          query {
            allWordpressWpApiMenusMenusItems (filter: {wordpress_id: {eq: 2}}) {
              edges {
                node {
                  name
                  items {
                    object_id
                    object_slug
                    title
                    url
                    type
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <ul className={`atom-navigation ${className}`}>
            {data.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(function (item) {
              return (
                <li
                  key={item.object_id}
                  onClick={self.handleClickMenu}
                >
                  <Link
                    className={item.object_slug === 'contact' ? 'contact-button' : ''}
                    to={`/${item.object_slug}`}
                    key={item.object_slug}
                  >
                    {item.title}
                  </Link>
                </li>
              )
            })}
          </ul>
        )}
      />
    )
  }
}

AtomNavigation.defaultProps = {
  className: "",
}


export default AtomNavigation;

