// Basics
import React from 'react';
import Helmet from 'react-helmet';
// import { Location } from "@reach/router";

// Libraries
import { getSeoForClientRenderedPage } from '../../Atoms/Seo/Library';

// View
import Header from '../../Molecules/Header';

const TemplateWrapper = ({
  children,
  isContactPage = false,
  isHomePage = false,
  // location,
  link,
  seo = null,
  title
}) => {

  // CSS class
  const className = isContactPage
    ? 'header-default-color'
    : '';

  return (
    <div
      className={className}
      id="wrapper"
    >
      {/* <Helmet title={title + (isHomePage ? '' : ' | Appinfini Technologies')} /> */}
      {getSeoForClientRenderedPage({
        isHomePage,
        link,
        seoDetails: seo,
        title
      })}
      <Header />
      {children}
    </div>
  );
}

export default TemplateWrapper
