// Basics
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Utilities
import {
  get,
  isEmpty,
  size
} from 'lodash';

// Libraries
// import {getAppNameFull} from '../../../libraries/App';
import { generateRandomNumber } from '../../../libraries/Utility';

// Views
import CoreDocumentHeadManager from '../../Core/DocumentHeadManager/DocumentHeadManager';

// App name
const appName = 'Appinfini Technologies';

const renderCanonicalTag = (content) => {
  return (
    <link rel="canonical" href={content} />
  );
};

const renderMetaTag = (name, content) => {
  return (
    <meta key={generateRandomNumber()} name={name} content={content} />
  );
};

const renderMetaTagOpenGraph = (name, content) => {
  return (
    <meta key={generateRandomNumber()} property={`og:${name}`} content={content} />
  );
};

const renderMetaTagTwitter = (name, content) => {
  return (
    <meta key={generateRandomNumber()} name={`twitter:${name}`} content={content} />
  );
};

const renderMetaTagImageProp = (url) => {
  return (
    <meta key={generateRandomNumber()} itemProp="image" content={url} />
  );
};

const renderMetaTagImageOpenGraph = (url) => {
  return (
    <meta key={generateRandomNumber()} property="og:image" content={url} />
  );
};

const renderMetaTagImageTwitter = (url) => {
  return (
    <meta key={generateRandomNumber()} property="twitter:image:src" content={url} />
  );
};

const renderSchema = (schemas) => {

  // If no schema
  if (size(schemas) < 1) {
    return null;
  }

  // Return schemas
  return (
    <script
      key={generateRandomNumber()}
      type="application/ld+json"
    >
      {JSON.stringify(schemas)}
    </script>
  );

};

class AtomSeo extends Component {

  render() {
    const {
      fields,
      schemas
    } = this.props;

    // If SEO detail is empty
    if (
      (size(fields) < 1)
      && (size(schemas) < 1)
    ) {
      return null;
    }

    // Social information
    const social = get(fields, 'yoast_wpseo_social_defaults');

    // Default image
    const imageDefault = get(social, 'og_default_image.source_url');

    // Accessing fields
    const canonical = get(fields, 'yoast_wpseo_canonical');
    const title = get(fields, 'yoast_wpseo_title');
    const description = get(fields, 'yoast_wpseo_metadesc');
    const image = !isEmpty(get(fields, 'yoast_wpseo_image')) || imageDefault;
    const rating = get(fields, 'rating');
    const robots = get(fields, 'robots');
    const type = get(fields, 'type');
    const keywords = get(fields, 'keywords');

    const ogTitle = !isEmpty(get(fields, 'yoast_wpseo_facebook_title')) || title;
    const ogDescription = !isEmpty(get(fields, 'yoast_wpseo_facebook_description')) || description;
    const ogImage = !isEmpty(get(fields, 'yoast_wpseo_facebook_image')) || imageDefault;
    const ogSiteName = !isEmpty(get(fields, 'yoast_wpseo_website_name')) || appName;
    const ogType = get(fields, 'yoast_wpseo_facebook_type');
    const ogUrl = get(fields, 'yoast_wpseo_social_url');

    const twitterTitle = !isEmpty(get(fields, 'yoast_wpseo_twitter_title')) || title;
    const twitterDescription = !isEmpty(get(fields, 'yoast_wpseo_twitter_description')) || description;
    const twitterCardsType = get(social, 'twitter_card_type');
    const twitterImage = !isEmpty(get(fields, 'yoast_wpseo_twitter_image')) || imageDefault;
    const twitterSite = get(social, 'twitter_site');

    return (
      <CoreDocumentHeadManager>

        <title>{size(title) > 0 ? title : appName}</title>
        {size(title) > 0 && renderMetaTag('title', title)}
        {size(description) > 0 && renderMetaTag('description', description)}
        {size(canonical) > 0 && renderCanonicalTag(canonical)}
        {size(rating) > 0 && renderMetaTag('rating', rating)}
        {size(robots) > 0 && renderMetaTag('robots', robots)}
        {size(type) > 0 && renderMetaTag('type', type)}
        {size(keywords) > 0 && renderMetaTag('keywords', keywords)}
        {size(image) > 0 && renderMetaTagImageProp(image)}

        {size(ogTitle) > 0 && renderMetaTagOpenGraph('title', ogTitle)}
        {size(ogDescription) > 0 && renderMetaTagOpenGraph('description', ogDescription)}
        {size(ogSiteName) > 0 && renderMetaTagOpenGraph('site_name', ogSiteName)}
        {size(ogType) > 0 && renderMetaTagOpenGraph('type', ogType)}
        {size(ogUrl) > 0 && renderMetaTagOpenGraph('url', ogUrl)}
        {size(ogImage) > 0 && renderMetaTagImageOpenGraph(ogImage)}

        {size(twitterTitle) > 0 && renderMetaTagTwitter('title', twitterTitle)}
        {size(twitterDescription) > 0 && renderMetaTagTwitter('description', twitterDescription)}
        {size(twitterCardsType) > 0 && renderMetaTagTwitter('type', twitterCardsType)}
        {size(twitterImage) > 0 && renderMetaTagImageTwitter(twitterImage)}
        {size(twitterSite) > 0 && renderMetaTagTwitter('site', twitterSite)}

        {renderSchema(schemas)}

      </CoreDocumentHeadManager>
    );
  }
}

AtomSeo.propTypes = {
  fields: PropTypes.object,
  schemas: PropTypes.object,
};

export default AtomSeo;
