 // Basics
import React from 'react';

// Utilities
import {
  concat,
  get,
  isEmpty,
  map
} from 'lodash';

// Views
import AtomSeo from './index';

// Hooks
import { useSiteMetadata } from "../../../hooks/UseSiteMetadata";

/*
 * Get breadcrumb schema of page.
 *
 * @param object
 * @return array
 */
export const getBreadcrumbForPage = ({ brand, isHomePage, link, pageTitle, siteUrl }) => {

  // Homepage breadcrumb
  const homeBreadcrumb = [
    {
      "@type": "ListItem",
      "position": 1,
      "name": brand,
      "item": siteUrl
    }
  ];

  // Current page breadcrumb
  const pageBreadcrumb = [
    {
      "@type": "ListItem",
      "position": 2,
      "name": pageTitle,
      "item": link
    }
  ];

  // Return
  return {
    "@type": "BreadcrumbList",
    "itemListElement": concat(homeBreadcrumb, isHomePage ? [] : pageBreadcrumb)
  };
};

/*
 * Get JSON-LD schema.
 *
 * @param seoDetails object
 * @return string
 */
export const getSeoSchema = ({
  isHomePage,
  link,
  seoDetails,
  title: pageTitle
}) => {

  const {
    addresses,
    brand,
    contactPoints,
    description,
    email,
    founders,
    foundingDate,
    headline,
    knowsAbout,
    legalName,
    siteUrl,
    title,
  } = useSiteMetadata();

  // Telephone
  let businessTelephone = null;

  // Logo
  const logo = get(seoDetails, 'yoast_wpseo_company_logo.source_url');

  // Address
  const businessAddresses = map(addresses, (address) => {
    const {
      addressLocality,
      addressRegion,
      postalCode,
      streetAddress
    } = address;
    return {
      "@type": "PostalAddress",
      "addressLocality": addressLocality,
      "addressRegion": addressRegion,
      "postalCode": postalCode,
      "streetAddress": streetAddress,
    };
  });

  // Founders
  const businessFounders = map(founders, (founder) => {
    const {
      alumniOf,
      birthDate,
      email: founderEmail,
      gender,
      height,
      name: founderName,
      nationality,
      socialProfiles,
      telephone
    } = founder;
    return {
      "@type": "Person",
      "name": founderName,
      "email": founderEmail,
      // "image": "janedoe.jpg",
      "alumniOf": alumniOf,
      "birthDate": birthDate,
      "height": height,
      "gender": gender,
      "nationality": nationality,
      "telephone": telephone,
      "sameAs": socialProfiles
    }
  });

  // Contact points
  const businessContactPoints = map(contactPoints, (contactPoint) => {
    const {
      areaServed,
      telephone
    } = contactPoint;

    // Updating telephone number
    businessTelephone = telephone;

    return {
      "@type": "ContactPoint",
      "telephone": telephone,
      "areaServed": areaServed
    }
  });

  // Aggregate rating
  const businessAggregateRatings = {
    "@type": "AggregateRating",
    "ratingValue": "5",
    "reviewCount": "1"
  };

  // Reviews
  const businessReviews = [
    {
      "@type": "Review",
      "author": "Raja Mathur",
      "datePublished": "2020-01-31",
      "description": "I worked here last year and working here was my best experience yet. Seniors there helped me a lot in refining my skills.",
      "name": "Skilled people",
      "reviewRating": {
        "@type": "Rating",
        "bestRating": "5",
        "ratingValue": "5",
        "worstRating": "1"
      }
    }
  ];

  // Social websites
  const businessSocialProfiles = [
    'https://www.linkedin.com/company/appinfini/',
    'https://facebook.com/appinfini',
    'https://twitter.com/appinfini',
    'https://www.instagram.com/appinfini/'
  ];

  // Return
  return {
    "@context": "http://schema.org/",
    "@graph": [
      {
        "@type":"Organization",
        "address": businessAddresses,
        "aggregateRating": businessAggregateRatings,
        "brand": brand,
        "contactPoint": businessContactPoints,
        "email": email,
        "founders": businessFounders,
        "foundingDate": foundingDate,
        "knowsAbout": knowsAbout,
        "legalName": legalName,
        "logo": logo,
        "name": title,
        "review": businessReviews,
        "sameAs": businessSocialProfiles,
        "slogan": headline,
        "subOrganization":{
          "@type":"Organization",
          "name": brand,
          "url": siteUrl
        },
        "telephone": businessTelephone,
        "url": siteUrl,
      },
      {
        "@type": "LocalBusiness",
        "address": businessAddresses,
        "aggregateRating": businessAggregateRatings,
        "contactPoint": businessContactPoints,
        "email": email,
        "founders": businessFounders,
        "foundingDate": foundingDate,
        "image": logo,
        "knowsAbout": knowsAbout,
        "legalName": legalName,
        "logo": logo,
        "name": title,
        "openingHours": ["Mo-Sa 08:00-22:00", "Su 10:00-16:00"],
        "priceRange": "$",
        "review": businessReviews,
        "sameAs": businessSocialProfiles,
        "slogan": headline,
        "subOrganization": {
          "@type": "Organization",
          "name": brand,
          "url": siteUrl
        },
        "telephone": businessTelephone,
        "url": siteUrl,
      },
      {
        "@type":"CreativeWork",
        "keywords":"Web development company, Cost effective IT company, Progressive web app development company, Mobile application development company, App development company, Enterprise application development company, React Native app development company, App developemnt cost, outsource developers, how much does it cost to make an app, E-commerce web development company, app design company, web design company, UI/UX, ReactJS development company, hybrid mobile application development company, GatsbyJS development company, Headless CMS development company, Drupal customization, Wordpress customization, Laravel app development company",
        "workExample":{
          "@type":"CreativeWork",
          "text":"Entrepreneur, Freelancer"
        },
        "about": !isEmpty(get(seoDetails, 'yoast_wpseo_metadesc')) ? get(seoDetails, 'yoast_wpseo_metadesc') : description,
        "url": siteUrl
      },
      {
        "@type":"WebPage",
        "primaryImageOfPage":{
          "@type":"ImageObject",
          "image": logo
        },
        "breadcrumb": getBreadcrumbForPage({brand, isHomePage, link, siteUrl, pageTitle}),
      }
    ]
  };
};

/*
 * Get SEO for client rendered page.
 *
 * @param seoDetails object
 * @return string
 */
export const getSeoForClientRenderedPage = ({ isHomePage, link, seoDetails, title }) => {

    // Return
    return (
      <AtomSeo
        schemas={getSeoSchema({ isHomePage, link, seoDetails, title })}
        fields={seoDetails}
      />
    );
};

export default getSeoForClientRenderedPage;
