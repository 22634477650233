// Basics
import React, { createRef } from 'react';
import './index.scss';
import { Link, StaticQuery, graphql } from 'gatsby';
import { Container, Image, Responsive, Sticky, Ref } from 'semantic-ui-react'
import Icon from "@material-ui/core/Icon";
import { get } from 'lodash';

// View
import AtomNavigation from '../../Atoms/Navigation';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.contextRef = createRef();
    this.handleClickMenu = this.handleClickMenu.bind(this);
  }

  handleClickMenu() {
    document.body.classList.toggle('navbar-open');
  }

  render() {
    return (
      <Ref innerRef={this.contextRef}>
        <Sticky context={this.contextRef}>
          <div className="header">
            <Container>

              <StaticQuery
                query={graphql`
                query MyQuery {
                  wordpressAcfOptions {
                    options {
                      global_templates_header_and_footer {
                        header {
                          dummy
                          website_logo {
                            source_url
                          }
                        }
                      }
                    }
                  }
                }
        `}
                render={data => {
                  const image = get(data, 'wordpressAcfOptions.options.global_templates_header_and_footer.header.website_logo.source_url');
                  return (
                    <div id="logo">
                      <Link to="/">
                        <Image src={image} alt="Appinfini Technologies" />
                      </Link>
                    </div>
                  )
                }}
              />

              <Responsive maxWidth={991}>
                <span
                  className="hamburger-menu"
                  onClick={this.handleClickMenu}>
                  <Icon>menu</Icon>
                </span>
              </Responsive>

              <Responsive minWidth={991}>
                <div className="main-menu">
                  <AtomNavigation />
                </div>
              </Responsive>

              <Responsive maxWidth={991}>
                <div className="main-menu responsive">
                  <span className="close"
                    onClick={this.handleClickMenu}
                  >
                    <Icon>close</Icon>
                  </span>
                  <AtomNavigation />
                </div>
              </Responsive>

            </Container>
          </div>
        </Sticky>
      </Ref>
    )
  }
}

export default Header
