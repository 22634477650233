// Basics
import React from "react";
// import PropTypes from 'prop-types';

// Package
import {Helmet} from "react-helmet";

class CoreDocumentHeadManager extends React.Component {

    render() {
        const {children} = this.props;

        return (
          <div
            className="atom-document-head-manager"
          >
            <Helmet defer={false}>
              {children}
            </Helmet>
          </div>
        );
    }
}

// CoreDocumentHeadManager.propTypes = {
//     children: PropTypes.element.isRequired,
// };

export default CoreDocumentHeadManager;
