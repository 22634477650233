import {
    useStaticQuery,
    graphql
} from "gatsby";

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            addresses {
                addressLocality
                addressRegion
                postalCode
                streetAddress
            }
            brand
            contactPoints {
                areaServed
                telephone
            }
            description
            email
            founders {
                alumniOf
                birthDate
                email
                gender
                height
                name
                nationality
                socialProfiles
                telephone
            }
            foundingDate
            headline
            knowsAbout
            legalName
            siteUrl
            title
          }
        }
      }
    `
  )
    return site.siteMetadata;
}

export default useSiteMetadata;
